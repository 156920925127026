@import '../../assets/style/Variables.scss';

.agent-card {
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding: 16px;
    height: 100vh;
    overflow: scroll;
    flex-direction: column;
    // margin-top: 29px;
    @media (max-width: 767px) {
        margin-top: 0;
    }
    .form-label {
        font-weight: 800;
        color: $primary-color;
        font-size: 13px;
    }

    .form-control {
        margin-bottom: 0px;
    }

    .form-text {
        font-weight: 500;
        margin-top: 10px;
        font-size: 10px;
        img {
            width: 13px;
            margin-right: 7px;
            height: 13px;
            object-fit: contain;
        }
    }
    .card-bottom-btn{
        margin-top: 11px;
        .btn{
            background-color:  $primary-color;
            color: white;
            &:hover {
                background-color: $primary-color;
                color: white;
            }
    
            &:active {
                background-color: $primary-color;
                color: white;
            }
        }
    }
    .business-description{
        resize: none;
        height: 90px;
        overflow-y: scroll;
    }
}
  .question-scroll{
    overflow: scroll;
    .agent-card{
        height: calc(100vh - 120px);
        justify-content: center;
        margin: 0;
        .card{
            width: 400px;
        }
    }
  }
.question-cards-outer{
    margin-top: 20px;
    .question-cards{
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2392156863);
        padding: 20px;
        border-radius: 5px;
        height: 100%;
        position: relative;
        h5{
            font-size: 17px;
            font-weight: 600;
            padding-right: 26px
        }
        h6{
            font-size: 13px;
            line-height: 23px;
            color: #6a6a6a;
            padding-bottom: 30px;
        }
        .trash{
            position: absolute;
            width: 30px;
            height: 30px;
            background: #ffecec;
            border-radius: 50px;
            text-align: center;
            line-height: 30px;
            display: inline-block;
            right: 20px;
            cursor: pointer;
        }
        .fa-trash-can{
            color: red;
        }
        .action-btns{
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 15px;
            right: 0;
            left: 0;
            background-color: rgb(255, 255, 255);
           
            .fa-pen-to-square{
                color: #7366f2;
                color: #ffffff;
                margin-left: 10px;
            }
        }
        .knowledge-item{
            max-height: 200px;
            overflow-y: scroll;
        }
    }
    .knowledge-base{
        border: 2px dashed rgb(0 0 0);
        height: 100%;
        padding: 50px 20px;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        color: #aaaaaa;
        cursor: pointer;
        i{
            font-size: 40px;
        }
        h6{
            margin: 0;
            margin-top: 20px;
        }
    }
}
.knowledgeBase-tabs{
    background: #f1f1f1;
    padding: 4px;
    .btn{
        background-color: #f1f1f1 !important;
        color: #787878;
        padding: 4px 20px;
        font-size: 12px;
      &:hover,&:active{
        background-color: #d1d1d1;
        color: #787878;
      }
      &.active{
        background-color: white !important;
        color: $primary-color !important;
        border-radius: 0.375rem !important;
       &:active{
            background-color: #ffffff !important;
            color: #787878;
          }
          &:focus-visible{
            box-shadow: none;
          }
      }
    }
  }
  .plan-for-business{
    padding-top: 30px;
    .plan-title{
        color: #181059;
        font-size: 32px;
        font-weight: bold;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #181059;
        line-height: 19.36px;
    }
  }
  .plan-cards {
    border-radius: 24px;
    box-shadow: 1px 1px 24px #ebebeb;
    padding: 20px 28px;
    min-height: 410px;
    padding-top: 80px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    background-image: url(../images/plan-cards-shapetop.svg) , url(../images/plan-cards-shapebottom.svg);
    // background-position: 0px 0px;
    background-position:  top,  bottom;
    background-repeat: no-repeat;
    background-size: contain;
    .free-trail-btn{
        font-size: 16px;
        font-weight: 600;
        background-color: #8646ff;
    }
    .free-trail{
        color: #9F9F9F;
        font-size: 16px;
        font-weight: 600;
    }
    .email-credits{
        font-size: 16px;
        font-weight: 600;
        width: max-content;
        padding-left: 30px;
        img{
            margin-right: 6px;
            width: 12px;
        }
    }
    // .carry-forward{
    //     font-size: 16px;
    //     font-weight: 600;
    //     img{
    //         margin-right: 6px;
    //         width: 6px;
    //     }
    // }
    .email-cards{
        margin: 0 auto;
        display: table;
    }
    h3{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
            text-align: center;
            color: #8645FF;
            font-weight: 700;
    }
    .plan-price {
        color: #181059;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
        span{
                font-size: 25px;
                font-weight: 700;
        }
    }
    .plan-list{
        margin-top: 15px;
        p{
            position: relative;
            padding-left: 20px;
            margin-bottom: 12px;
            font-weight: 500;
            width: max-content;
            margin: 0 auto;
            i{
                position: absolute;
                font-size: 11px;
                top: 8px;
                left: 0;
            }
        }
    }
    &.annual-plan{
        background-color: #7669F5;
        margin-top: 0;
       h3,p{
        color: #fff;
       }
       .valid-till{
        color: white;
       }
       .plan-price {
        color: white;
    }
    .plan-discount{
        background-color: #FFFFFF;
        color: #8645FF;
        font-size: 12px;
        font-weight: 700;
        padding: 10px;
        display: inline-block;
        padding: 5px 8px 5px 8px;
        border-radius: 5px;
    }
    h3{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    }
    .purple-solid-btn{
        background-color: #8646ff;
        border-radius: 8px;
        width: 70%;
        color: #8645FF;
        font-size: 16px;
        font-weight: 700;
        height: 48px;
        color: white;
        margin: 0 auto;
        display: table;
    }
    .plan-button{
        // position: absolute;
        // bottom: 20px;
        // right: 0;
        // left: 0;
        margin: 0 auto;
        width: 82%;
    }
    .white-solid-btn{
        color: #7669F5;
        font-size: 20px;
        font-weight: 700;
        background-color: white;
        width: 100%;
    }
    .valid-till{
        font-size: 10px;
        color: #9a9a9a;
        text-align: center;
        margin-top: 4px;
        font-weight: 700;
    }
}