@import "../../assets/style/Variables.scss";

.meeting-schedule-card {
  border: 1px solid $primary-color;
  padding: 11px;
  border-radius: 10px;
  margin-bottom: 24px;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 12px;
    h4 {
      font-size: 14px;
      font-weight: 700;
      color: $primary-color;
      margin-bottom: 0;
      width: 303px;
    }
    img{
        width: 14px;
        cursor: pointer;
    }
    h5{
       color: #929292;
       font-size: 12px;
       margin-bottom: 0;
       width: 365px;
    }
    h6{
      color: #929292;
       font-size: 12px;
       margin-bottom: 0;
    }
    p{
      margin-bottom: 0;  
      font-size: 14px;
      font-weight: 600;
      text-align: left;
    }
  }
}
