.agent-background {
  height: 100vh;
  height: 95vh;
  // overflow: hidden;
  @media (max-width:767px) {
    overflow: visible;
  }
}
.resize-none {
  resize: none;
}
.primary-btn {
  background-color: #6e61ea;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  padding: 7px 30px;
}
.top-header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 22;
  position: sticky;
.subscription-days-left{
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
  .navbar {
    padding: 8px 27px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 222;

    @media (max-width: 767px) {
      padding: 8px;
    }

    .navbar-brand {
      cursor: default;

      img {
        width: 120px;
        cursor: pointer;
      }
    }

    .collapse {
      justify-content: space-between;

      @media (max-width: 992px) {
        justify-content: end;
      }
    }

    .navbar-collapse {
      .close-nav {
        position: absolute;
        right: 27px;
        top: 20px;

        &:focus {
          box-shadow: none;
        }

        @media (min-width: 992px) {
          display: none;
        }
      }

      @media (max-width: 992px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: white;
        transition: visibility .2s ease-in-out, transform .2s ease-in-out;
        padding: 22px;
      }

    }

    .show {
      @media (max-width: 992px) {
        visibility: visible;
        transform: translateX(-100%);
      }
    }

    .navbar-toggler {
      border: 1px solid white;
      background-color: white;
      padding: 4px 8px;

      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        width: 1em;
        height: 1em;
      }
    }

    .top-nav-link {
      display: flex;
      align-items: center;
      // margin-left: 28%;
      margin: 0 auto;

      // @media (max-width: 1400px) {
      //   margin-left: 17%;
      // }

      // @media (max-width: 1100px) {
      //   margin-left: 6%;
      // }

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: start;
        margin-left: 0;
      }

      .nav-item {
        list-style: none;
        padding: 5px 12px;
        margin-right: 21px;
        font-weight: 600;
        // color: black;
        text-transform: capitalize;
        cursor: pointer;
        font-size: 14px;

        @media (max-width: 992px) {
          margin-right: 0;
          margin-bottom: 11px;
        }

        &:hover {
          background-color: $primary-color;
          color: rgb(255, 255, 255);
          border-radius: 5px;

          @media (max-width: 992px) {
            background-color: transparent;
            color: #8f8f8f;
          }
        }
      }

      .active {
        background-color: $primary-color;
        color: rgb(255, 255, 255);
        border-radius: 5px;

        @media (max-width: 992px) {
          background-color: transparent;
          color: $primary-color;
        }
      }
    }

    .user-icon {
      width: 35px;
      margin-right: 10px;
    }

    .btn {
      background-color: transparent;
      padding: 0;
      font-size: 15px;

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: transparent !important;
      }
    }

    .user-dropdown {
      padding-right: 9px;

      @media (max-width: 992px) {
        padding: 11px;
      }

      .dropdown-toggle {
        color: #000;
        &::after {
          color: black;
        }
      }


      .dropdown-menu {
        margin-top: 18px;
        
        @media (max-width: 992px) {
          margin-top: 42px;
        }

        .dropdown-header {
          cursor: pointer;
          padding: 4px 13px;
          color: black;
          &:hover{
            background-color: #7669F5;
            color: #ffff;
          }
        }
      }

      .show {
        @media (max-width: 992px) {
          visibility: unset;
          transform: unset !important;
        }
      }
    }
  }
}

.btn {
  font-weight: 800;
  padding: 7px 20px;
  background-color: $primary-color;
  border-radius: 7px;
  border: 0;
  font-size: 14px;
  // cursor: default !important;

  @media (max-width: 767px) {
    padding: 7px 16px;
    font-size: 14px;
  }

  &:hover {
    background-color: $primary-color;
  }

  &:active {
    background-color: $primary-color !important;

  }
}

.btn-outline-secondary {
  border: 1px solid $primary-color;
  color: $primary-color;
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: white;
  }

  &:active {
    background-color: transparent !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
  }
}

.card {
  border: 0;
  max-width: 450px;
  border-radius: 19px;
  border: 1px solid $primary-color;

  .card-body {
    padding: 21px 34px;

    @media (max-width: 767px) {
      padding: 1rem;
    }

    .card-content-loader {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #a8a8a89c;
      border-radius: 18px;

      .spinner-border {
        color: #5346c3;
      }
    }

  }
}

.button-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.question-page-loader-container {
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  position: fixed;
  background: #00000061;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  .spinner-border{
    color: #7669f5 !important;
  }
}
.common-spinner{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000006b;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border{
    color: #7669f5 !important;
  }
}
.btn-close-white{
  filter: unset !important;
}

.infowebhook {
  margin-top: 8px;
  img{
      width: 15px;
      margin-right: 8px;
  }
}
.subscribtion-bar {
  background-color: #FF6363;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  display: flex;
    justify-content: space-between;
  .subscribe-txt {
    display: flex;
    align-items: center;
    cursor: pointer;
    img{
      margin-left: 10px;
    }
}
}
.month-tabs{
  min-width: 300px;
  max-width: 50%;
  border-radius: 50px;
  padding: 0px;
  border: none;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 1px 1px 10px #e0e0e0;
  .nav-item {
    padding: 6px;
    width: 50%;
    .nav-link {
      border-radius: 50px !important;
      color: #969696;
      cursor: pointer;
      &:hover{
        border-color: transparent;
      }
  }
    a.active.nav-link {
      background-color: #7366f2 !important;
      color: white !important;
      border-color: #7366f2 !important;
  }
}
}