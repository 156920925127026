@import '../../assets/style/Variables.scss';

.email-category-modal {
    .modal-header {
        padding: 17px 35px;

        @media (max-width: 767px) {
            padding: 1rem;
        }

        .modal-title {
            color: $primary-color;
            font-weight: 700;

            @media (max-width: 767px) {
                font-size: 1.12em;
            }
        }

    }

    .modal-body {
        padding: 17px 35px;

        @media (max-width: 767px) {
            padding: 1rem;
        }

        .form-label {
            font-weight: 500;
            color: #404040;
        }

        input {
            border-color: #E0E2E7;
            background-color: #F9F9FC;
        }

        .add-btn {
            padding: 4px 11px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            font-size: 13px;
        }

        .remove-items-container {
            padding: 12px 29px;
            height: 146px;
            overflow-y: auto;

            .remove-items {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 12px;

                .form-check-input {
                    border-color: $primary-color;
                    margin-right: 12px;
                    border-radius: 0;
                    box-shadow: none;

                    &:checked {
                        background-color: $primary-color;
                    }
                }

                .form-check-label {
                    text-transform: capitalize;
                    font-size: 14px;
                    margin-top: 3px;

                }

                .remove-btn {
                    padding: 0px;
                    border-radius: 100%;
                    width: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 22px;
                    font-size: 17px;
                }
            }
        }

        .selected-items-container {
            border-radius: 10px;
            background-color: #F9F9FC;

            .items-header {
                border-bottom: 1px solid #D9DBE9;
                padding: 14px 21px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h5 {
                    color: #404040;
                    margin-bottom: 0;
                    font-size: 16px;
                }

                img {
                    width: 15px;
                    object-fit: contain;
                }
            }



        }
    }

    .modal-footer {
        .btn {
            padding: 7px 13px;
            font-size: 14px;
            border-radius: 6px;
        }
    }
}

.integrate-modal {
    .accordian-heading {
        text-align: center;
        font-size: 22px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 12vh;
        margin-bottom: 30px;
    }
    .answer-textarea{
        resize: none;
        overflow: scroll;
        height: 120px;
    }
    p{
        font-weight: 200;
        text-align: center;
    }
    .modal-body {
        background-color: #251d630d;
    }
    .infowebhook {
        margin-top: 8px;
        img{
            width: 15px;
            margin-right: 8px;
        }
    }
}

.email-thread-modal {
    .offcanvas-header{
        padding-bottom: 0;
    }
    .loader-container{
        width:394px;
        height: 100%;
        background: #0000008c;
        position: fixed;
        margin: 0;
        margin: 0 auto;
        top: 0;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-details {
        border: 1px solid #CECECE;
        border-radius: 9px;
        padding: 15px;
        margin-bottom: 15px;
    }
    .offcanvas-title{
        h4{
            color: #695EEE;
            font-weight: 900;
            font-size: 22px;
            margin:0;
        }
    }
    .content-detail {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .item {
            font-weight: 900;
            text-transform: capitalize;
            font-size: 15px;
            margin-right: 10px;
            color: #4a4949;
        }

        .value {
            font-weight: 700;
            font-size: 14px;
            color: #676767;
        }
    }

    .note {
        font-size: 14px;
        font-weight: 500;
        border: none;
        max-height: 373px;
        min-height: 250px;
        width: 100%;
        border-radius: 4px;
        color: #3E3E3E;
        line-height: 21px;
        &:focus{
            outline: none;
        }
    }

    .title {
        font-weight: 800;
        margin-bottom: 10px;
        font-size: 16px;
        color: $primary-color;
    }
}
.subscription-modal{
    .modal-header{
        justify-content: center;
        p{
            font-weight: 500;
            font-size: 25px;
        }
    }
}