@import '../../assets/style/Variables.scss';

.booking-container {
    display: flex;
    justify-content: center;
    padding: 16px;
    height: 100vh;
    overflow: scroll;
    .list-calender-container {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        @media (max-width:767px) {
            display: block;
        }
        .booking-list-card {
            border-radius: 7px;
            width: 413px;

            @media (max-width: 992px) {
                margin-top: 17px;
                width: 100%;
            }

            .card-header {
                padding: 14px 29px;
                font-weight: 600;
                font-size: 18px;
                text-align: center;
            }

            .card-body {
                padding: 0;

                .list-details-container {
                    height: 372px;
                    overflow: scroll;
                    margin: 3px 0 17px 0;

                    .loader-container {
                        position: absolute;
                        background-color: rgb(74 68 128 / 26%);
                        z-index: 2;
                        top: 56px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 88%;
                        width: 100%;
                        border-radius: 0 0 7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                    .no-booking-data{
                        text-align: center;
                        margin: 0;
                        margin-top: 32px;
                        font-weight: 800;
                        
                    }

                    .list-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 11px 15px;
                        border-bottom: 1px solid #f2f2f2;

                        &:last-child {
                            border-bottom: 0;
                        }

                        .list-details-card {
                            background-color: #EDEBFF;
                            padding: 8px 9px;
                            border-radius: 3px;
                            margin: 3px;
                            @media (max-width: 767px) {
                                padding: 8px 7px;
                            }

                            h3 {
                                font-size: 13px;
                                text-transform: capitalize;
                                color: #a2a2a2;
                                margin-bottom: 0;

                                @media (max-width: 767px) {
                                    font-size: 11px;
                                }
                            }

                            p {
                                margin: 0;
                                font-size: 12px;
                                font-weight: 900;
                                white-space: nowrap;
                                width: 96px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                @media (max-width: 767px) {
                                    font-size: 11px;
                                }
                            }
                        }

                        .accordian-icons {
                            img {
                                width: 15px;
                                margin: 4px;
                            }
                        }
                    }
                }
            }

        }

        .rbc-calendar {
            @media (max-width:1500px) {
                height: 30rem !important;
                width: 50rem !important;
            }
            .rbc-month-view{
                border: 1px solid $border-color;
                font-size: 13px;
                @media (max-width:767px) {
                     width: 89%;
                    margin: 0 auto;
                }
            }
            @media (max-width:767px) {
                width: 100% !important;
            }
            .rbc-month-row{
                overflow: visible;
                border-top: 1px solid $border-color;
                @media (max-width:767px) {
                    font-size: 11px;
                }
            }
            .rbc-day-bg + .rbc-day-bg{
                border-left: 1px solid $border-color;
            }
            @media (max-width: 1220px) {
                width: 377px;
            }

            @media (max-width: 992px) {
                width: 100%
            }

            .rbc-toolbar {
                margin-bottom: 0;

                .rbc-btn-group {
                    padding: 20px;
                    @media (max-width:767px) {
                        padding: 10px 0;
                        font-size: 10px;
                    }
                }
                button{
                    font-size: 14px;
                }
            }

            .rbc-header {
                padding: 16px;
                border-bottom: 1px solid $border-color;
                @media (max-width:767px) {
                    font-size: 10px;
                    padding: 10px;
                }
            }

        }
    }
}
.rbc-label {
    @media (max-width:767px) {
        font-size: 11px;
    }
}