@import '../../assets/style/Variables.scss';

.dashboard-card {
    padding: 17px 42px;

    @media (max-width: 767px) {
        padding: 18px 18px;
    }

    .cards-list {
        margin: 8px 0 16px;
    
        h4 {
            color: #404040;
            font-weight: 800;
            font-size: 18px;
        }

        p {
            color: #7B7B7B;
            font-weight: 600;
            margin-bottom: 26px;
            font-size: 14px;
        }

        .card-container {
            @media (max-width: 767px) {
                gap: 12px;
            }

            .card-box {
                border-radius: 5px;
                border: none;
                padding: 20px;
                height: 100%;
                box-shadow: 0px 0px 8px 0px #95939378;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h3 {
                    font-size: 44px;
                    color: #000000;
                    font-weight: 700;
                    margin: 0;
                }
                h4{
                    color: #525252;
                    font-weight: 700;
                    font-size: 14px;
                    align-items: center;
                    display: flex;
                }
                .status-icon {
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                    text-align: center;
                    align-items: center;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    img{
                        width: 30px;
                    }
                }
            }

            .light-purple {
                .status-icon{
                    background-color: #EAE8FF;
                }
            }

            .light-green {
                .status-icon{
                    background-color: #E4F8FF;
                }
            }

            .light-orange {
                .status-icon{
                    background-color: #FFE2DD;
                }
            }

            .light-yellow {
                .status-icon{
                    background-color: #E8FFEC;
                }
            }
        }
    }

    .table-responsive {
        height: 59vh;
        padding-bottom: 30px;
        .table>:not(caption)>*>*{
            border-bottom-width:0px;
            background-color: transparent;
        }
        tbody,
        thead {
            th {
                position: sticky;
                top: -2px;
                box-shadow: none;
                font-size: 14px;
                font-weight: 700;
            }

            .no-data {
                text-align: center;
                font-size: 17px;

                td {
                    padding: 50px;
                     border:none;
                     font-weight: 500;

                    &:hover {
                        background-color: #F6F6FF;
                        box-shadow: none;
                    }
                }
            }
           th{
            font-weight: 900;
            background: rgb(255, 255, 255) !important;
           }
            th,
            td {
                padding: 15px 10px;
                white-space: nowrap;
                font-size: 14px;
            }

            .btn {
                padding: 3px 11px;
                font-size: 12px;
                font-weight: 600;
                border-radius: 4px;
                background-color: transparent;
                color: #7669f5;
                font-weight: 700;
                font-size: 14px;
                padding: 0;
            }


        }
        tr {
            &:hover {
                background-color: #F6F6FF;
                cursor: pointer;
            }
        }

    }
}
.pagination{
    display: flex;
    justify-content: center;
    .page-link{
        color: #7669f5;
        cursor: pointer;
    }
    button{
        border:none;  
        font-weight: 600;
        &:hover{
            background-color: transparent;
        }
        &:focus{
            box-shadow: none;
            background-color: transparent;
        }
    }
    li.disabled button{
        background-color: transparent !important;
        color: #212529bf;
    }
}
.pagination-nav{
    position: fixed;
    bottom: 0;
    width: fit-content;
    margin: 0 auto;
    right: 0;
    left: 0;
    background-color: white;
}