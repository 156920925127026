@import '../../assets/style/Variables.scss';

.accordion-start-btn {
    margin-top: 11px;
    width: 90%;
    text-align: right;
    @media (min-width: 1800px) {
        width: 60%;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}

.email-categories-accordion {
    background-color: #F7F7FB;
    border: 1px solid $primary-color;
    width: 90%;
    margin: 0 auto;
    padding: 18px 27px;
    border-radius: 19px;
    height: 80%;
hr{
    border-color: #bbbbbb;
}
    @media (min-width: 1800px) {
        width: 60%;
    }

    @media (max-width: 767px) {
        padding: 1rem;
        width: 100%;
    }

    .alert-message {
        font-size: 12px;
        color: #929292;
        margin-bottom: 8px;
    }

    .btn {
        padding: 4px 11px;
        font-size: 13px;
        border-radius: 5px;
    }

    .accordion {
        max-height: 342px;
        overflow: auto;
        padding-right: 6px;

        .accordion-data {
            display: flex;

            .accordion-item {
                width: 100%;
                border-color: $primary-color;
                border-radius: 14px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                margin-bottom: 13px;

                @media (max-width: 767px) {
                    margin-bottom: 1em;
                }

                .accordion-header {
                    margin-bottom: 0;

                    .accordion-button {
                        background-color: transparent;
                        box-shadow: none;
                        padding: 12px 18px;
                        font-weight: 600;
                        text-transform: capitalize;
                        font-size: 14px;

                        @media (max-width: 767px) {
                            padding: 1em;
                        }

                    }
                }

                .accordion-body {
                    padding: 5px 17px;
                    text-transform: capitalize;
                    padding-top: 0;

                    @media (max-width: 767px) {
                        padding: 1em;
                        font-size: 14px
                    }
                }
            }

            .accordian-icons {
                display: flex;
                margin-left: 10px;
                padding-top: 8px;

                img {
                    width: 15px;
                    width: 17px;
                    margin-left: 9px;
                    height: 17px;
                    object-fit: contain;
                }

                :hover {
                    cursor: pointer;
                }
            }

            .book-appointment-table {
                text-align: center;
                font-size: 14px;

                thead {
                    position: sticky;
                    top: -2px;

                    th {
                        color: #998ff7;
                        font-weight: 700;
                    }
                }

                tbody {
                    border-bottom: 0 solid transparent;

                    tr {
                        &:last-child {
                            border-bottom: 1px solid #dee2e6;
                        }
                    }

                    td {
                        padding: 8px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .accordian-heading {
        color: $primary-color;
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 0;

        @media (max-width: 767px) {
            font-size: 1em;
        }
    }

    .delete-icon {
        border: 1px solid #c1c1c1;
        border-radius: 100px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;

        .delete-category-icon {
            width: 16px;
        }
    }


}