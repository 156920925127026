.labels-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // height: 100vh;
  padding: 26px 0px;
  font-size: 15px;
  .labels-form-wrapper {
    // width: 48%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    padding: 0px 28px 24px 28px;
    .labels-form-heading{
      h4{
        font-weight: 800;
        font-size: 18px;
      }
    }
    .labels-form-input {
      width: 100%;
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 1.6em;
        color: #7669f5;
      }
      .label {
        font-weight: 800;
        font-size: 18px;
      }
      .form-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-switch {
          display: flex;
          align-items: center;
          .form-check-label {
            font-size: 16px;
            font-weight: 700;
            color: #181059;
          }
          .form-check-input:checked {
            background-color: $primary-color;
          }
        }
      }
      input {
        width: 50px;
        border: 2px solid #e2e2e2;
        height: 24px;
        padding: 0px 10px;
        border-radius: 100px;
        margin-right: 10px;
      }
      button {
        background-color: #6e61ea;
        color: #fff;
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 1.2em;
        padding: 7px 30px;
      }
    }
  }
  .labels-mail-wrapper {
    // width: 48%;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    .icon {
      display: flex;
      .img1 {
        margin-right: 14px;
        width: 19px;
        cursor: pointer;
      }
      .img2 {
        cursor: pointer;
        margin-right: 0px;
        height: 18px;
      }
    }
    h4{
      color: #404040;
      font-weight: 800;
      font-size: 18px;
    }
    h3 {
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
      color: #333333;
    }
    .mailbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
     
      p{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      border-bottom: 1px solid rgb(212, 212, 212);
      &:last-child{
        border-bottom: 0px;
      }
      span {
        margin-right: 5px;
      }
      .icon {
        display: flex;
        align-items: center;
        img {
          width: 15px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .labels-mail-box {
      // margin-top: 20px;
      background-color: #f9f9fc;
      border-radius: 10px;
      border: 1px solid rgb(226, 223, 223);
      width: 100%;
      // height: 80%;
      height: 450px;
      overflow-y: auto;
    }
    thead > tr > * {
      background: #e1dffd;
      padding: 13px 28px;
      font-size: 14px;
      font-weight: 700;
    }
    tbody > tr > * {
      padding: 13px 28px;
      font-size: 14px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
    }
    tbody > tr:nth-of-type(odd) > * {
      background-color: #f5f5fe;
      padding: 13px 28px;
    }
  }
}
