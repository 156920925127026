@import '../../assets/style/Variables.scss';

.action-card {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    padding: 16px;

    .card-body {
        text-align: center;

        h4 {
            font-weight: 800;
            margin-bottom: 8px;
        }

        img {
            width: 94px;
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 22px;

        }
    }
}
