.support-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(246, 241, 250);
  height: 100vh;
  font-size: 15px;
  .support-page-container {
    width: 50%;
    height: 50%;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45),
      25px 0 20px -20px rgba(0, 0, 0, 0.45),
      0px 25px 20px -20px rgba(0, 0, 0, 0.45),
      -25px 0 20px -20px rgba(0, 0, 0, 0.45);

    .heading-container {
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 1.5em;
      }
    }
    .image-container {
      width: 70%;
      height: 60%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contact-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 15%;
      .contact-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 50%;
        .icon {
          width: 21px;
          height: 21px;
          margin-right: 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          margin: 0;
          font-weight: 700;
          font-size: 0.8em;
        }
      }
    }
  }
}

.support {
  padding: 50px 0px;
  .support-chat-wrapper {
    .header {
      padding: 12px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #E5E5EA;
      border-bottom: none;
      h3 {
        font-size: 14px;
        font-weight: 700;
        color: #2C2C2E;
        margin-bottom: 2px;
      }
      p {
        margin-bottom: 0;
        color: #666668;
        font-size: 13px;
      }
    }
  }
  .support-chat {
    height: 72vh;
    padding: 12px 4px;
    overflow-y: auto;
    border: 1px solid #E5E5EA;
    .rce-container-mbox {
      margin-bottom: 8px;
      .rce-mbox {
        width: 340px;
        background-color: #F2F2F7;
        .rce-mbox-title {
          color: #2C2C2E;
          font-weight: 700;
        }
        &.rce-mbox-right {
          background-color: $primary-color;
          color: #fff;
          width: 340px;
          .rce-mbox-title {
            color: #fff;
            font-weight: 700;
          }
        }
        .rce-mbox-right-notch {
          fill: $primary-color;
        }
        .rce-mbox-left-notch {
          fill: #F2F2F7;
        }
      }
    }
  }
  .chat-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 0px;
    img {
      height: 26px;
    }
    .form-control {
      width: 96%;
      padding: 10px .75rem;
      border: none;
      border-bottom: 1px solid #E5E5EA;
      border-radius: 0px;
      &::placeholder {
        color: #676767;
        font-weight: 600;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .rce-container-input {
      min-width: unset;
      width: 92%;
      border-bottom: 1px solid #E5E5EA;
      textarea {
        
      }
      .rce-input-textarea {
        height: unset !important;
        padding: 0px 5px;
      }
    }
    .rce-button {
      background-color: #6e61ea !important;
      width: auto;
    }
  }
}
.chat-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  h2 {
    color: $primary-color;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  img {
    width: 20px;
    object-fit: contain;
    cursor: pointer;
    margin-left: 10px;
  }
}