
@import '../../assets/style/Variables.scss';

.login-container {
    height: 100vh;
    overflow: hidden;
    @media (max-width:767px) {
        overflow: visible;
    }
    .login-content {
        height: 100vh;
        justify-content: space-between;
        padding: 30px 0;
        align-items: center;
        .card-inner {
            margin: 20px 0;
                    h2 {
                        font-weight: 800;
                        line-height: 49px;
                        margin-bottom: 20px;
                        color: $primary-color;
                        @media (max-width: 992px) {
                            line-height: 28px;
                            margin-bottom: 10px;
                            font-size: 1.1em;
                          }
                    }

                    p {
                        font-size: 18px;
                        margin-bottom: 20px;
                        line-height: 27px;
                        @media (max-width: 992px) {
                            font-size: 1em;
                            line-height: 23px;
                        }
                    }
                    .login-btn{
                        @media (max-width: 767px) {
                            display: flex;
                            justify-content: center;
                        }
                    }
        }
        .right-image-section {
            // box-shadow: 0px 0px 37px rgba(0, 0, 0, 0.25);
            padding: 20px;
            border-radius: 40px;
            // padding: 30px;
            height: 100%;
            .front-desk-ai{
                width: 100%;
                display: table;
                margin-left: auto;
            }
            .nav-tabs{
                box-shadow: 0px 0px 12px #cbcbcb;
                border-radius: 50px;
                max-width: 300px;
                margin: 0 auto;
                li.nav-item {
                    width: 50%;
                    text-align: center;
                    .nav-link{
                        color: rgba(191, 191, 191, 1);
                        font-weight: 600;
                        border: unset;
                        cursor: pointer;
                        @media (max-width:767px) {
                            padding: 9px;
                        }
                    }
                }
                .active.nav-link {
                    background-color: rgba(118, 105, 245, 1);
                    color: white !important;
                    border-radius: 50px;
                }
            }
            .tab-img{
                height: 540px;
                @media (max-width:767px) {
                    height: 350px;
                }
                img {
                    width: 100%;
                    object-fit: contain;
                    // max-height: fit-content;
                    height: 100%;
                    @media (max-width: 767px) {
                      margin-top: 8px;
                      height: inherit;
                    }
                }
            }
            
        }
    }
    .logo-header {
        padding: 20px 0;
        height: 80px;
        img {
            width: 230px;
            cursor: pointer;
            @media (max-width: 992px) {
                width: 160px;
         }
        }
    }
}

.video-wrapper{
    -webkit-appearance: none !important;
    position: fixed;
    right: 8px;
    bottom: 0;
    padding: 0;
}

/* Close button */
.close {
  width: 36px;
  height: 36px;
  color: #fff;
  background-color: #000000b0;
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 24px;
  border-radius: 18px;
  border: 2px solid #333;
  padding: 0;
  line-height:38px;
}